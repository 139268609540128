<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small" @submit.native.prevent>
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" @keyup.enter.native="query"/>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<!-- <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button> -->
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="80" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">已审核</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="warning">待审核</el-tag>
					<el-tag v-if="scope.row.status === 3" size="mini" type="warning">管理员审核</el-tag>
					<el-tag v-if="scope.row.status === 4" size="mini" type="danger">驳回</el-tag>
				  </template>
				</el-table-column>
				<el-table-column label="名称" min-width="200" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="联系人" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.work_person }}
				  </template>
				</el-table-column>
				<el-table-column label="电话" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.phone }}
				  </template>
				</el-table-column>
				<el-table-column label="分会" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.grade_name }}
				  </template>
				</el-table-column>
				<el-table-column label="申请文件" min-width="110" align="center">
				  <template slot-scope="scope">
					<div style="color: #2d8cf0;cursor: pointer;" @click="handleViewFile(scope.row.word)">查看</div>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="170">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center" fixed="right">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="审核" placement="top" v-if="scope.row.status == 2 || scope.row.status == 3">
					  <el-button type="warning" icon="el-icon-setting" size="mini" circle @click="handleOperate(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 审核-->
		<el-dialog :visible.sync="operate" width="800px" :close-on-click-modal="false" append-to-body :title="title">
			<el-form ref="operate" :model="operateForm" label-width="120px" size="small" :rules="rules">
			    <el-form-item  label="审核状态" prop="status">
					<el-radio-group v-model="operateForm.status">
					   <el-radio :label="1">通过</el-radio>
					   <el-radio :label="4">驳回</el-radio>
					 </el-radio-group>
			    </el-form-item>
				<el-form-item  label="审批意见" prop="remarks">
				    <el-input v-model="operateForm.remarks" placeholder="请输入审批意见" type="textarea" :rows="3" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
			  <el-button size="mini" @click="operateCancel">取 消</el-button>
			  <el-button type="primary" size="mini" @click="handleOperateSubmit">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 查看文件-->
		<el-dialog :visible.sync="office" width="800px" :close-on-click-modal="false" append-to-body :title="title">
			<iframe :src="file_url" frameborder="0" width="100%" height="600"></iframe>
			<div slot="footer" class="dialog-footer">
			  <el-button size="mini" @click="officeCancel">取 消</el-button>
			  <el-button type="primary" size="mini" @click="officeCancel">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips
		},
		data() {
			return {
				preUrl: 'apply_ls',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				rules:{
					status: [
					  { required: true, message: '请选择审核状态', trigger: 'blur' }
					],
				},
				operate:false,
				operates:[
					{id:1,name:'通过'},
					{id:3,name:'驳回'},
				],
				operateForm:{
					id:'',
					status:'',
					remarks:'',
				},
				office:false,
				file_url:"",
			}
		},
		mounted() {

		},
		methods:{
			reset() {
			  this.operateForm = {
			    id:'',
			   	status:'',
			   	remarks:'',
			  }
			  this.resetForm('form')
			},
			handleOperate(row){
				this.reset()
				this.operate = true
				this.title = '审核'
				this.operateForm.id = row.id
			},
			operateCancel(){
				this.operate = false
			},
			handleOperateSubmit(){
				this.$refs.operate.validate(valid => {
				  if (valid) {
					  this.axios.post('/manage/apply_ls/operate', this.operateForm).then(res => {
					    if (res.status) {
							this.$message.success('审核成功')
							this.operate = false
							this.getList()
							this.reset()
					    } else {
					  	    this.$message.error(res.msg)
					    }
					  })
				  }
				})
			},
			officeCancel(){
				this.office = false
			},
			handleViewFile(url){
				this.office = true
				this.file_url = 'https://view.officeapps.live.com/op/view.aspx?src='+encodeURIComponent('https://api.mem.ahpea.cn/'+url)
			},
			handleDownload(url){
				this.office = true
				this.file_url = 'https://api.mem.ahpea.cn/'+url
			},
		}
	}
</script>

<style>
</style>